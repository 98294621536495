<script lang="ts" setup>
import type { SbFooterStoryblok } from '@/types'
import { useCurrentLanguageClass } from '@/composables/useCurrentLanguageClass'

interface Props {
  blok: SbFooterStoryblok
}

defineProps<Props>()
const { currentLanguageClass } = useCurrentLanguageClass('footer-items')
</script>

<template>
  <section :class="['footer-items', currentLanguageClass]">
    <div class="footer-items__sections">
      <div
        v-for="section in blok.sections"
        :key="section._uid"
        class="footer-items__section"
      >
        <p class="footer-items__section-title">
          {{ section.section_label }}
        </p>
        <ul class="footer-items__section-links">
          <li v-for="item in section.items" :key="item._uid">
            <SbFooterItem :item="item" />
          </li>
        </ul>
      </div>
      </div>
      <div class="footer-items__sections--mobile">
        <Accordion>
          <AccordionItem
          v-for="section in blok.sections" 
          :key="section._uid"
          :accordion-header="section.section_label"
          is-in-footer
          >
          <template  #accordion-content>
                <ul class="footer-items__section-links">
                  <li v-for="item in section.items" :key="item._uid">
                    <SbFooterItem :item="item" />
                  </li>
                </ul>
          </template>
          </AccordionItem>
        </Accordion>
      </div>
  </section>
</template>

<style lang="scss" scoped>
.footer-items {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  color: var(--color-text);
  row-gap: 56px;

  @media (min-width: $breakpoint-lg) {
    row-gap: 0;
    column-gap: 160px;
    place-items: start;
    margin-bottom: 80px;
  }

  &.footer-items--de {
    @media (min-width: $breakpoint-lg) {
      column-gap: 96px;
    }
  }

  &__sections {
    flex-direction: column;
    row-gap: 32px;
    width: 100%;
    align-content: space-between;
    display: none;

    @media (min-width: $breakpoint-lg) {
      flex-wrap: wrap;
      max-height: 480px;
      display: flex;
    }

    &--mobile {
      display: block;

        @media (min-width: $breakpoint-lg) {
        display: none;
      }

    }
  }

  &__section {
    display: flex;
    flex-direction: column;
    gap: 4px;

    @media (min-width: $breakpoint-lg) {
      border: 0;
      margin: 0;
      padding: 0;
    }
  }

  &__section-title {
    @include paragraph-s;
    font-weight: var(--font-weight-paragraph-bold);
    margin: 0;
  }

  &__section-links {
    display: flex;
    flex-direction: column;
  }
}
</style>
